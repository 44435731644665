{
  "name": "luidais",
  "version": "2.0.15",
  "eSignature": {
    "requiredVersion": "2.3.4",
    "recommendedVersion": "2.3.7",
    "signingExtensionName": "eParakstsTokenSigning"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 192.168.0.127",
    "build": "ng run luidais:build:test-env",
    "build:prod": "ng run luidais:build:production",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.6",
    "@angular/cdk": "^19.1.4",
    "@angular/common": "^19.1.6",
    "@angular/compiler": "^19.1.6",
    "@angular/core": "^19.1.6",
    "@angular/forms": "^19.1.6",
    "@angular/platform-browser": "^19.1.6",
    "@angular/platform-browser-dynamic": "^19.1.6",
    "@angular/router": "^19.1.6",
    "@ant-design/icons-angular": "^19.0.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@ctrl/tinycolor": "^4.1.0",
    "@kolkov/angular-editor": "^2.0.0-beta.0",
    "apexcharts": "^4.5.0",
    "date-fns": "^2.28.0",
    "file-saver": "^2.0.5",
    "html2canvas": "^1.4.0",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "ng-apexcharts": "^1.15.0",
    "ng-zorro-antd": "^19.0.2",
    "ngx-device-detector": "^9.0.0",
    "ol": "^10.4.0",
    "ol-layerswitcher": "^4.1.2",
    "proj4": "^2.15.0",
    "rxjs": "~7.5.2",
    "tslib": "^2.3.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-eslint/schematics": "^18.4.3",
    "@angular/build": "^19.1.7",
    "@angular/cli": "^19.1.7",
    "@angular/compiler-cli": "^19.1.6",
    "@angular/language-service": "^19.1.6",
    "@types/node": "^17.0.10",
    "less": "^4.2.0",
    "nz-tslint-rules": "^0.901.2",
    "ts-node": "~10.4.0",
    "typescript": "~5.5.4"
  }
}
